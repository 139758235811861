import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home"
import About from "./components/About/About"
import Services from "./components/Services/Services"
import Resume from "./components/Resume/Resume"
import Portfolio from "./components/Portfolio/Portfolio"
import Testimonials from "./components/Testimonials/Testimonials"
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import TextEditor from './components/TextEditor/TextEditor'; // Updated import path
import PostComponent from './components/Posts/PostsPage';
import PostDetails from './components/Posts/PostDetails'; // Import the component for post details
// import dotenv from "dotenv";

import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify';
import Register from './components/Register/Register';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import StatisticsSidebar from './components/Dashboard/pages/Statistics/Statistics';


// dotenv.config();

function App() {
  return <>
  			<ToastContainer
				position='top-right'
				autoClose={1500}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
				theme='light'
				transition={Slide}
			/>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><Home /><About /><Services /><Resume /><Portfolio /><Testimonials /><Contact /><Footer /></>} />
        <Route path="/newpost" element={<TextEditor />} />
        <Route path="/posts" element={<PostComponent />} />
        <Route path="/posts/pages/:pageNumber" element={<PostComponent />} /> {/* Route for paginated posts */}
        <Route path="/posts/:postId" element={<PostDetails />} /> {/* Route for individual post */}
        <Route path='/admin/register' element={<Register />} /> 
        <Route path='/admin/login' element={<Login />} /> 
        <Route path='/dashboard' element={<Dashboard />} /> 
        <Route path='/statistics' element={<StatisticsSidebar />} /> 
      </Routes>
    </BrowserRouter>
  </>;
}

export default App;
