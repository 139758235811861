import React, { useState, useEffect } from 'react';
import './Posts.css'; // Import CSS file for styling
import { useParams } from 'react-router-dom';
import { instance } from '../src/axios';

const Posts = ({search}) => {
  const { pageNumber } = useParams();
  const [posts, setPosts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(pageNumber); // Initialize currentPage with pageNumber prop

  
  useEffect(() => {
    // Redirect to /posts/pages/1 if pageNumber is undefined
    if (!pageNumber) {
      window.location.href = '/posts/pages/1';
    } else {
      // Fetch posts data if pageNumber is defined
      const fetchPosts = async () => {
        try {
          let response;
          if (search) {
            // Fetch search results with pagination
            response = await instance.get(`api/posts/search?keyword=${search}&pageNumber=${pageNumber}`);
            setPosts(response.data);
          } else {
            // Fetch posts for the specified page
            response = await instance.get(`api/posts?page=${pageNumber}`);
            setPosts(response.data.posts);
            setTotalPages(response.data.totalPages);
          }
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      };
  
      fetchPosts();
    }
  }, [pageNumber, search]); // Trigger effect when pageNumber or search changes
  


// Function to check if a post is liked in local storage
const isPostLiked = (postId) => {
    const likedPosts = JSON.parse(localStorage.getItem('likedPosts')) || [];
    return likedPosts.includes(postId);
  };
  
  // Function to toggle the like status of a post in local storage
  const toggleLike = (postId) => {
    let likedPosts = JSON.parse(localStorage.getItem('likedPosts')) || [];
    if (likedPosts.includes(postId)) {
      // If post is already liked, remove it from the list
      likedPosts = likedPosts.filter(id => id !== postId);
    } else {
      // If post is not liked, add it to the list
      likedPosts.push(postId);
    }
    localStorage.setItem('likedPosts', JSON.stringify(likedPosts));
  };
  
// Function to handle like/unlike action for a post
const handleLike = async (postId, setPosts) => {
    try {
      // Check if the post is already liked locally
      const isLiked = isPostLiked(postId);
      if (!isLiked) {
          // Update local storage to indicate that the post is liked
          toggleLike(postId);
          // Update the like count in the local state
          setPosts(prevPosts => prevPosts.map(post => {
              if (post._id === postId) {
                  return { ...post, likes: post.likes + 1 };
                }
                return post;
            }));
        // Send a request to the backend to increase the like count for the post
        await instance.post(`api/posts/${postId}/like`);
      } else {
          // Update local storage to indicate that the post is unliked
          toggleLike(postId);
          // Update the like count in the local state
          setPosts(prevPosts => prevPosts.map(post => {
          if (post._id === postId) {
              return { ...post, likes: post.likes - 1 };
            }
            return post;
        }));
    // Send a request to the backend to decrease the like count for the post
    await instance.post(`api/posts/${postId}/unlike`);
      }
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };
  
  
  

  return (
    <div className="posts-container"> 
      {Array.isArray(posts) && posts?.length > 0 ? (
        posts.map(post => (
        <div key={post._id} className="post"> 
        <div className="top">
            <div className="userDeatils">
                <div className="profileImg">
                    <img src="https://images.pexels.com/photos/1261427/pexels-photo-1261427.jpeg" alt="user" className="cover" />
                </div>
                <h3>Akbar Dadamirzayev<br /><span>Web Developer</span></h3>
            </div>
            {/* <div className="dot">
                <img src="dot.png" alt="dot" />
            </div> */}
        </div>
        {post.titlePic ? <a href={`/posts/${post._id}`}><img src={post.titlePic} alt={post.title} className="post-image" /></a> : ""}
          <div className="btns">
            <div className="left">
                <img
                    src={isPostLiked(post._id) ? "https://i.ibb.co/1GvwY4T/heart-red.png" : "https://i.ibb.co/vdcQW8s/heart.png"}
                    alt="heart"
                    className="heart"
                    onClick={() => handleLike(post._id, setPosts)}
                />

                {/* <img src="https://i.ibb.co/vdcQW8s/heart.png" alt="heart" className="heart" onClick={() => handleLike(post._id)} /> */}
                <img src="https://i.ibb.co/Ny1ftvY/comment.png" alt="comment" />
                <img src="https://i.ibb.co/GRnw0fX/share.png" alt="share" />
            </div>
            
            <h4 className="likes">{post.likes} likes</h4>
        </div>
        <div className='keywords'>
          {post.keywords.length > 0 && (
              <span className="keywords">
                {post.keywords.map(keyword => (
                  <span key={keyword} className="postKeywords">{keyword.replace(" ", '')}</span>
                ))}
              </span>
            )}
        </div>
          <h3>{post.title}</h3>
          <p>{truncateText(stripHtmlTags(post.body), 30)}</p>
          {/* Render comments if needed */}
          {/* <p>Created At: {new Date(post.createdAt).toLocaleDateString()}</p> Display createdAt */}
          <h5 className="postTime">{formatTimeAgo(post.createdAt)}</h5>
        </div>
        ))
      ) : (
        <p style={{textAlign: "center", top: "10px", position: "absolute", left: "0", right: "0", fontSize: "18px"}}>No posts to display</p>
      )}
      <div className="pagination">
      {pageNumber > 1 && (
  <a href={`/posts/pages/${pageNumber - 1}`}>
    <button disabled={currentPage === 1}>
      Previous Page
    </button>
  </a>
)}

        {Array.from({ length: totalPages > 1 }, (_, index) => (
  <a key={index + 1} href={`/posts/pages/${index + 1}`}>
    <button disabled={pageNumber == index + 1}>
      {pageNumber == index + 1 ? index + 1 : index + 1}
    </button>
  </a>
))}
        {pageNumber < totalPages && (
  <a href={`/posts/pages/${Number(pageNumber) + 1}`}>
    <button disabled={currentPage === totalPages}>
      Next Page
    </button>
  </a>
)}

      </div>
    </div>
  );
};

// Function to strip HTML tags from a string and exclude <img> and <iframe> elements
const stripHtmlTags = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    const textContent = div.textContent || div.innerText || '';
    // Exclude images and iframes from the text content
    return textContent.replace(/<(img|iframe)[^>]*>/g, '');
  };

// Function to truncate text to a maximum number of words and append "...all" after them
const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length <= maxWords) {
      return text;
    } else {
      const truncatedText = words.slice(0, maxWords).join(' ');
      return truncatedText + '...all';
    }
  };
  
  function formatTimeAgo(createdAt) {
    const currentTime = new Date();
    const postTime = new Date(createdAt);
    const timeDifference = currentTime - postTime;
  
    // Convert time difference from milliseconds to seconds
    const seconds = Math.floor(timeDifference / 1000);
  
    // Define time intervals in seconds
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60
    };
  
    // Iterate through intervals to determine the appropriate unit of time
    for (const [unit, interval] of Object.entries(intervals)) {
      const count = Math.floor(seconds / interval);
      if (count > 0) {
        return count === 1 ? `${count} ${unit} ago` : `${count} ${unit}s ago`;
      }
    }
  
    // If the time difference is less than a minute, display "Just now"
    return 'Just now';
  }
  

export default Posts;
