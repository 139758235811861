import React, { useState } from 'react'
import "./Home.css"

import LOGO from "../../images/logo.bc3f064298319fa0c11c.png"
import HERO from "../../images/hero.png"

function Home() {
  const [active, setActive] = useState(0);
  const [home, setHome] = useState(0);

  const onClick = () => {
    if (active) {
      setActive(0)
    } else {
      setActive(1)
    }
  }

  const handleScroll = () => {
	  if (window.scrollY > 60) {
		setHome(1)
	  } else {
		setHome(0)
	  }
  }

  window.addEventListener('scroll', handleScroll);
  return <section className={home ? "home active" : "home"} id="home">
	
	{/* <!--   === Navbar Starts ===   --> */}
	<nav className="navbar">
		<div className="logo">
			<img src={LOGO} alt='' />
		</div>

		<ul className={active ? "nav-links active" : "nav-links"}>
			<li><a href="#home">Asosiy</a></li>
			<li><a href="#about">Men Haqimda</a></li>
			<li><a href="#services">Hizmatlar</a></li>
			<li><a href="#portfolio">Portfolio</a></li>
			<li><a href="#testimonials">Fikrlar</a></li>
			<li><a href="/posts">BLOG</a></li>
		</ul>

		<a href="#contactForm" className="button-wrapper">
			<button className="btn contact-btn">Aloqa</button>
		</a>

		<div className={active ? "menu-btn active" : "menu-btn"} onClick={onClick}>
			<span className="bar"></span>
			<span className="bar"></span>
			<span className="bar"></span>
		</div>
	</nav>
	{/* <!--   === Navbar Ends ===   --> */}

	{/* <!--   === Hero Starts ===   --> */}
	<div className="hero">
		<div className="hero-text">
			<h3>Salom</h3>
			<h1>Men Akbarshoh</h1>
			<h2>Full Stack dasturlovchisi.</h2>
			<p>Full Stack loyihalarining asosan Backend yo'nalishida faoliyat yuritaman va Telegram botlar tuzish bo'yicha tajribam katta.</p>
			<button className="btn hire-btn">Aloqaga chiqish</button>
		</div>

		<div className="hero-image">
			<img src={HERO} alt='' />
		</div>
	</div>
	{/* <!--   === Hero Ends ===   --> */}

	{/* <!--   === Career Bar Starts ===  --> */}
	<div className="career-container">
		<div className="career-item">
			<span className="icon"><i className="fa-solid fa-briefcase"></i></span>
			<span className="career-desc">
				<h2>3 Yillik</h2>
				<p>Tajriba</p>
			</span>
		</div>

		<div className="career-item">
			<span className="icon"><i className="fa-solid fa-file"></i></span>
			<span className="career-desc">
				<h2>50+ Proyektlar</h2>
				<p>Tugallangan</p>
			</span>
		</div>

		<div className="career-item">
			<span className="icon"><i className="fa-solid fa-heart"></i></span>
			<span className="career-desc">
				<h2>20+ Mamnun</h2>
				<p>Mijozlar</p>
			</span>
		</div>
	</div>
	{/* <!--   === Career Bar Ends ===  --> */}

</section>
}

export default Home