import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { instance } from '../src/axios';
import "./PostDetails.css"


const PostDetails = () => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [comment, setComment] = useState('');
  const [commenter, setCommenter] = useState('');
  const [comments, setComments] = useState([]);
  const [repliedComment, setRepliedComment] = useState({ name: "", comment: "", id: "" });


  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const post = await instance.get(`/api/posts/postID/${postId}`).catch(err => {
          if ((err.response.status === 500 && err.response.data.error === "Error fetching post details") || (err.response.status === 404 && err.response.data.error==="Post not found")) {
            alert("Noto'g'ri manzilga kirganga o'xshaysiz")
            navigate("/posts/pages/1")
          }
        });
        setPost(post.data);
        const commentsResponse = await instance.get(`/api/posts/${postId}/comments`);
        if (commentsResponse.data.message === 'No comments found for this post') {
          setComments([]);
        } else {
          setComments(commentsResponse.data.comments);
        }
      } catch (error) {
        console.error('Error fetching post details:', error);
      }
    };

    fetchPostDetails();
  }, [postId]);

  const isPostLiked = (postId) => {
    const likedPosts = JSON.parse(localStorage.getItem('likedPosts')) || [];
    return likedPosts.includes(postId);
  };

  const handleReply = (comment) => {
    setRepliedComment({ name: comment.commenterName, content: comment.commentContent, id: comment._id });
  };

  const toggleLike = (postId) => {
    let likedPosts = JSON.parse(localStorage.getItem('likedPosts')) || [];
    if (likedPosts.includes(postId)) {
      likedPosts = likedPosts.filter(id => id !== postId);
    } else {
      likedPosts.push(postId);
    }
    localStorage.setItem('likedPosts', JSON.stringify(likedPosts));
  };

  const handleLike = async (postId) => {
    try {
      const isLiked = isPostLiked(postId);
      if (!isLiked) {
        toggleLike(postId);
        setPost(prevPost => ({ ...prevPost, likes: prevPost.likes + 1 }));
        await instance.post(`/api/posts/${postId}/like`);
      } else {
        toggleLike(postId);
        setPost(prevPost => ({ ...prevPost, likes: prevPost.likes - 1 }));
        await instance.post(`/api/posts/${postId}/unlike`);
      }
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleSubmitComment = async (event) => {
    event.preventDefault();
    try {
      if (comment) {
        if (repliedComment) {
          await instance.post(`/api/posts/${postId}/comments`, { commenterName: commenter, commentContent: comment, parentCommentName: repliedComment.id });
          
        } else {
          await instance.post(`/api/posts/${postId}/comments`, { commenterName: commenter, commentContent: comment });
          
        }
        const response = await instance.get(`/api/posts/${postId}/comments`);
        setComments(response.data.comments);
        setComment('');
        console.log(response.data.comments);
      }
      } catch (error) {
        console.error('Error adding comment:', error);
    }
  };

  if (!post) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '200px', // Increased size
          height: '200px', // Increased size
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
          borderRadius: '10px', // Rounded corners
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Soft shadow
        }}
      >
        <div
          style={{
            width: '100px', // Increased size
            height: '100px', // Increased size
            position: 'relative',
            animation: 'rotate-animation 1s linear infinite',
          }}
          className='half-circle-spinner'
        >
          <div
            style={{
              width: '30px', // Increased size
              height: '30px', // Increased size
              borderRadius: '45%',
              backgroundColor: '#4CAF50', // Green color
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              animation: 'bounce-animation 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite',
            }}
            className='circle circle-1'
          ></div>
          <div
            style={{
              width: '30px', // Increased size
              height: '30px', // Increased size
              borderRadius: '45%',
              backgroundColor: '#1f95f1', // Orange color
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) rotate(180deg)',
              animation: 'bounce-animation 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite',
            }}
            className='circle circle-2'
          ></div>
        </div>
        <p style={{ marginTop: '20px', fontSize: '20px', color: '#555' }}>Loading...</p>
      </div>
    );
  }
  

  return (
    <div className='post_details'>
      <div key={post._id} className="post">
        <div className="top">
          <div className="userDeatils">
            <div className="profileImg">
              <img src="https://images.pexels.com/photos/1261427/pexels-photo-1261427.jpeg" alt="user" className="cover" />
            </div>
            <h3>Akbar Dadamirzayev<br /><span>Web Developer</span></h3>
          </div>
        </div>
        {post.titlePic ? <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={post.titlePic} alt={post.title} className="post-image" /> : ""}
        <div className="btns">
          <div className="left">
            <img
              src={isPostLiked(post._id) ? "https://i.ibb.co/1GvwY4T/heart-red.png" : "https://i.ibb.co/vdcQW8s/heart.png"}
              alt="heart"
              className="heart"
              onClick={() => handleLike(post._id, setPost)}
            />
            <img src="https://i.ibb.co/Ny1ftvY/comment.png" alt="comment" />
            <img src="https://i.ibb.co/GRnw0fX/share.png" alt="share" />
          </div>
          <h4 className="likes">{post.likes} likes</h4>
        </div>
        <div className='keywords'>
          {post.keywords.length > 0 && (
            <span className="keywords">
              {post.keywords.map(keyword => (
                <span key={keyword} className="postKeywords">{keyword.replace(" ", '')}</span>
              ))}
            </span>
          )}
        </div>
        <h3>{post.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: post.body }} />
        <h5 className="postTime">{formatTimeAgo(post.createdAt)}</h5>
      </div>
      <div className="comment-section">
        <div className="comment-header">
          <h2>Comments</h2>
        </div>
        <form className="comment-form">
          <textarea
            className="comment-input"
            placeholder="Add a public comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="comment-form-content">
            <input
              className="comment-input"
              placeholder="What is your name?"
              value={commenter}
              onChange={(e) => setCommenter(e.target.value)}
            />
            <input
              className="comment-input"
              placeholder="Reply to..."
              value={repliedComment.name ? `@${repliedComment.name}: ${repliedComment.content}` : ``}
              disabled
              onChange={(e) => setRepliedComment(e.target.value)}
            />
            <button className="comment-submit" type="submit" onClick={handleSubmitComment}>
              Comment
            </button>
          </div>
        </form>
        <div className="comments">
          {comments?.map((comment, index) => (
            <div key={index} className="comment">
              <div className="comment-content">
                <p><span className="comment-author">{comment.commenterName} ({formatTimeAgo(comment.createdAt)}):</span>{comment.commentContent}</p>
              </div>
              <div className="comment-actions">
                <button className="reply-button" onClick={() => handleReply(comment)}>
                  Reply
                </button>
                {/* Add other action buttons if needed */}
              </div>
              {/* <hr /> */}
            </div>
          ))}
        </div>
        {/* <div className="pagination">
          <button className="pagination-button">Previous</button>
          <span className="pagination-info">Page X of Y</span>
          <button className="pagination-button">Next</button>
        </div> */}
      </div>
    </div>
  );
};

function formatTimeAgo(createdAt) {
  const currentTime = new Date();
  const postTime = new Date(createdAt);
  const timeDifference = currentTime - postTime;

  const seconds = Math.floor(timeDifference / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60
  };

  for (const [unit, interval] of Object.entries(intervals)) {
    const count = Math.floor(seconds / interval);
    if (count > 0) {
      return count === 1 ? `${count} ${unit} ago` : `${count} ${unit}s ago`;
    }
  }

  return 'Just now';
}

export default PostDetails;