import React, { Component } from "react";
import Slider from "react-slick";
import "./Testimonials.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { instance } from "../src/axios";

export default class PauseOnHover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browserWidth: window.innerWidth,
      testimonials: [] // Store testimonials data fetched from the server
    };
  }

  handleWindowResize = () => {
    this.setState({
      browserWidth: window.innerWidth
    });
  };

  fetchTestimonials = async () => {
    try {
      const response = await instance.get("/testimonials"); // Replace with your backend endpoint URL
      if (response.status != 200) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.data;
      this.setState({ testimonials: data });
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    this.fetchTestimonials(); // Fetch testimonials when the component mounts
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  render() {
    const { testimonials, browserWidth } = this.state;

    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: browserWidth < 700 ? 1 : 2, // Adjust the number of slides based on the browser width
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true
    };

    return (
      <section className="testimonials reusable" id="testimonials">
        {/* === Headings Text Starts === */}
        <header className="headings">
          <h3>Mijozlar</h3>
          <h1>Odamlarning fikrlari</h1>
          <p>Men haqimda mijozlarning samimiy fikrlari.</p>
        </header>
        {/* === Headings Text Ends === */}

        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div className="item testimonial-card" key={testimonial._id}>
              <main className="test-card-body">
                <div className="quote">
                  <i className="fa fa-quote-left"></i>
                  <h2>{testimonial.title}</h2>
                </div>
                <p>{testimonial.data}</p>
                <div className="ratings">
                  {Array.from({ length: testimonial.rating }, (_, index) => (
                    <i className="fa-solid fa-star" key={index}></i>
                  ))}
                </div>
              </main>
              <div className="profile">
                <div className="profile-image">
                  <img src={testimonial.pic} alt="" />
                </div>
                <div className="profile-desc">
                  <span>{testimonial.name}</span>
                  <span>{testimonial.description}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    );
  }
}
