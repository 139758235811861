import React from 'react'
import "./Footer.css"

function Footer() {
  return <section className="page-footer">
	
  <footer className="footer-contents">
      <a href="#">Akbarshoh</a>
      <p> <span>ADI</span> | All rights reserved.</p>
  </footer>
  
</section>
}

export default Footer