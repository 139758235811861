import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { instance } from '../src/axios'; // Import instance from the correct location
import { toast } from 'react-toastify';
import Sidebar from './Sidebar';
import "./Dashboard.css"


function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [isOpen, setIsOpen] = useState(true); // State for managing sidebar open/close
  const navigate = useNavigate();
  
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if not authenticated
      navigate('/admin/login');
    } else {
      // Fetch user data if authenticated
      fetchUserData();
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      // Use instance instead of axios
      const response = await instance.get('/admin').catch((err)=>{
        toast.error(err.response.data.error);
        localStorage.clear(); // Clear all items in local storage
        navigate('/admin/login');
      });
      setUserData(response.data);
      localStorage.setItem('admin', JSON.stringify(response.data)); // Stringify the object before storing
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  

  return (
    <div style={{display: "flex"}}>
      {/* Sidebar */}
      <Sidebar userData={userData}/>
      <div className="content">
        <h2>{userData?.name}ning admin paneli</h2>
        {userData && (
    <div className="dashboard">
    {/* <div className="sidebar">
      <h2>Dashboard</h2>
      <ul>
        <li>Home</li>
        <li>Analytics</li>
        <li>Reports</li>
        <li>Settings</li>
      </ul>
    </div> */}
    <div className="main-content">
      <h2>Welcome to Your Dashboard</h2>
      <div className="cards">
        <div className="card">
          <h3>Users</h3>
          <p>10,000</p>
        </div>
        <div className="card">
          <h3>Revenue</h3>
          <p>$50,000</p>
        </div>
        <div className="card">
          <h3>Orders</h3>
          <p>500</p>
        </div>
      </div>
    </div>
  </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
