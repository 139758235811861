import { useEffect, useState } from "react";
import "./Register.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { instance } from "../src/axios";

function Register() {
  const navigate = useNavigate();
  const preset_key = "wjfzw5qb";
  const cloud_name = "dqxvh09uy";
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if not authenticated
      navigate('/admin/login');
    } else {
      // Fetch user data if authenticated
      fetchUserData();
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      // Use instance instead of axios
      const response = await instance.get('/admin').catch((err)=>{
        toast.error(err.response.data.error);
        localStorage.clear(token);
        navigate('/admin/login');
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    repeatPassword: "",
  });
  const [image, setImage] = useState(null);


  const handleImageUpload = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      // setImage(URL.createObjectURL(selectedImage));

      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("upload_preset", preset_key);
      axios
        .post(
          `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
          formData
        )
        .then((res) => setImage(res.data.secure_url))
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (image == null) {
      toast("Please select image!");
    } else if (formData.password !== formData.repeatPassword) {
      toast("Passwords should be similar!");
    } else {
      instance.post("/admin/register", {
          name: `${formData.firstName} ${formData.lastName}`,
          username: formData.username,
          password: formData.password,
          image: image,
        })
        .then((res) => {
          console.log(res.data.message);
        })
        .catch((err) => {
          console.log(err.response.data.error);
          toast.error(err.response.data.error);
          if (err.response.data.error === "Username already exists!") {
            toast("User with this email is already registered!")
          }
        });
    }

    // Form validation

    // Call API to register user
  };

  return (
    <>
      <form className="modal" onSubmit={handleSubmit}>
        <div
          className={`profile-pic ${image ? "has-image" : "dragging"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="dashes"></div>
          <label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            Upload Image
          </label>
        </div>

        <input
          type="text"
          placeholder="First Name"
          value={formData.firstName}
          onChange={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
          className="input-field"
          required
        />

        <input
          type="text"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
          className="input-field"
          required
        />

        <input
          type="username"
          placeholder="Username"
          value={formData.username}
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          className="input-field"
          required
        />

        <input
          type="password"
          placeholder="Password"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
          className="input-field"
          required
        />

        <input
          type="password"
          placeholder="Repeat Password"
          value={formData.repeatPassword}
          onChange={(e) =>
            setFormData({ ...formData, repeatPassword: e.target.value })
          }
          className="input-field"
          required
        />

        <button type="submit" className="submit-btn">
          Register
        </button>
      </form>
      <ToastContainer />
    </>
  );
}

export default Register;