import { useEffect, useState } from "react";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { instance } from "../src/axios";

function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // User is already authenticated, redirect to the chat page
      window.location.href = "/dashboard";
    }
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await instance.post("/admin/login", {
        username: formData.username,
        password: formData.password,
      });

      const { token } = response.data;

      if (token) {
        // Store the token in application memory (localStorage)
        localStorage.setItem("token", token);

        // Redirect the user to the chat page or perform other actions
        window.location.href = "/dashboard";
      } else {
        toast.error("Token data not received");
      }
    } catch (error) {
      if (error.response && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        console.error(error);
        toast.error("An error occurred during login");
      }
    }
  };

  return (
    <>
      <form className="login-form" onSubmit={handleSubmit}>
        <h1>Login</h1>

        <input
          type="username"
          placeholder="Username"
          value={formData.username}
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          className="input-field"
          required
        />

        <input
          type="password"
          placeholder="Password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          className="input-field"
          required
        />

        <button type="submit" className="submit-btn">
          Login
        </button>
      </form>
      <ToastContainer />
    </>
  );
}

export default Login;