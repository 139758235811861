import React from 'react'
import "./Services.css"

function Services() {
  return <section className="services reusable" id="services">
	
  {/* <!--   === Headings Text Starts ===   --> */}
  <header className="headings">
      <h3>Hizmatlar</h3>
      <h1>Men ajoyib xizmatlarni taqdim etaman</h1>
      <p>Men Full Stack Dasturchi sifatida Frontend dasturchisi bilan Backend dasturchisini ishlarini qila olaman va asosan Backend. AWS serveridan mukammal ravishda ishlay olaman va bu bo'yicha ham tajribam katta.</p>
  </header>
  {/* <!--   === Headings Text Ends ===   --> */}

  {/* <!--   === Services Box Container Starts ===   --> */}
  <div className="services-container">
      
      <div className="service-box">
          <div className="icon-wrapper">
              <i className="fa-solid fa-server"></i>
          </div>
          <h2>AWS</h2>
          <p>Amazon Web Serverdan mukammal foydalanib ishning unumdorligini oshirishga yordam beraman Ubuntuda ham tajribaliman.</p>
          <h3>30+ Web Saytlar</h3>
      </div>

      <div className="service-box">
          <div className="icon-wrapper">
              <i className="fa-solid fa-code"></i>
          </div>
          <h2>Web Dasturlash</h2>
          <p>Web dasturlash bo'yicha yana asosan Telegram botlarni mukammal va chiroyli tarzda yaratishim ko'pchilikka manzur kelgan</p>
          <h3>30+ botlar</h3>
      </div>

      <div className="service-box">
          <div className="icon-wrapper">
              <i className="fa-solid fa-users"></i>
          </div>
          <h2>Guruh bilan ishlash</h2>
          <p>Guruh bilan ishlash hammaning ham qo'lidan kelavermaydi, men esa guruh bilan ishlashni yaxshi ko'raman ayniqsa ular tajribali bo'lishsa.</p>
          <h3>40+ guruh a'zolari</h3>
      </div>

  </div>
  {/* <!--   === Services Box Container Ends ===   --> */}

</section>
}

export default Services