import React, { useState, useEffect } from 'react';
import Posts from './Posts';
import "./PostsPage.css";
import { instance } from '../src/axios';

function PostsPage() {
  const [lastPosts, setLastPosts] = useState([]);
  const [popularPosts, setPopularPosts] = useState([]);
  const [popularKeywords, setPopularKeywords] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch last 5 posts, most popular 3 posts, and popular keywords from backend
        const response = await instance.get('api/posts/summary');
        setLastPosts(response.data.last);
        setPopularPosts(response.data.popular);
        setPopularKeywords(response.data.popularKeywords);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  return (
    <div className="posts-page">
      <div className="posts-container">
        <Posts search={search}/>
      </div>
      <div className="menu">
        <div className="search-container">
          <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Keywords..." />
          <button>Search</button>
        </div>
        <h2>Last Posts</h2>
        <ul className="last-posts-list">
          {lastPosts.map(post => (
            <li key={post._id}><a href={`/posts/${post._id}`}>{post.title}</a></li>
          ))}
        </ul>
        <h2>The Most Popular</h2>
        <ul className="last-posts-list">
          {popularPosts.map(post => (
            <li key={post._id}><a href={`/posts/${post._id}`}>{post.title}</a></li>
          ))}
        </ul>
        <h2>Keywords</h2>
        <div className="keywords">
          {popularKeywords.map(keyword => (
            <span onClick={()=>setSearch(keyword)} className="keyword" key={keyword}>{keyword}</span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PostsPage;
