import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { FaHome, FaUser, FaBriefcase, FaImages, FaEnvelope, FaChartBar, FaCog } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { RiAdminFill } from 'react-icons/ri';
import { GrUserAdmin } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({userData}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); 

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // Clear token from localStorage
    localStorage.removeItem('token');
    // Redirect to login page
    navigate('/admin/login');
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="toggle-btn" onClick={toggleSidebar}>
        {/* {isOpen ? 'Close' : 'Open'} */}
        <img src={userData?.image} alt={userData?.username} /> <p>{isOpen && userData?.username}</p>
      </button>
      <nav>
        <ul>
          {isOpen && (
            <>
              <li><a href="/dashboard"><FaHome className="icon" />Dashboard</a></li>
              <li><a href="/newpost"><FaUser className="icon" />Add New Post</a></li>
              <li><a href="/projects"><FaBriefcase className="icon" />Add New Project</a></li>
              <li><a href="/testimonials"><FaImages className="icon" />Add Testimonial</a></li>
              <li><a href="/statistics"><FaChartBar className="icon" />Statistics</a></li>
              <li><a href="/admin/settings"><FaCog className="icon" />Settings</a></li>
              <li><a href="/admin/register"><RiAdminFill className='icon' />Add Admin</a></li>
              <li style={{cursor: "pointer"}}><a type='button' onClick={handleLogout}><FiLogOut className='icon'/>Logout</a></li>
            </>
          )}
          {!isOpen && (
            <>
            <li><a href="/dashboard"><FaHome className="icon" /></a></li>
            <li><a href="/newpost"><FaUser className="icon" /></a></li>
            <li><a href="/projects"><FaBriefcase className="icon" /></a></li>
            <li><a href="/testimonials"><FaImages className="icon" /></a></li>
            <li><a href="/statistics"><FaChartBar className="icon" /></a></li>
            <li><a href="/admin/settings"><FaCog className="icon" /></a></li>
            <li><a href="/admin/register"><RiAdminFill className='icon'/></a></li>
            <li><a type='button' onClick={handleLogout} ><FiLogOut className='icon'/></a></li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
