import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's Snow theme CSS
import './TextEditor.css'; // Import custom CSS for styling
import axios from 'axios'; // Import Axios for making HTTP requests
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer for displaying notifications
import { instance } from '../src/axios';
import { useNavigate } from 'react-router-dom';

const TextEditor = () => {
  const navigate = useNavigate();
  const [editorHtml, setEditorHtml] = useState('');
  const [postHeader, setPostHeader] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if not authenticated
      navigate('/admin/login');
    } else {
      // Fetch user data if authenticated
      fetchUserData();
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      // Use instance instead of axios
      const response = await instance.get('/admin').catch((err)=>{
        toast.error(err.response.data.error);
        localStorage.clear(token);
        navigate('/admin/login');
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(content);
  };

  const handleKeywordsChange = (e) => {
    setKeywords(e.target.value.split(','));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file); // Set the uploaded image file to the state
    } else {
      alert('Please select a valid image file (JPEG, PNG, GIF).');
      e.target.value = ''; // Clear the file input
    }
  };

  const handleSave = async () => {
    try {
      let imageUrl = null;
  
      // First, upload the image to Cloudinary if it exists
      if (image) {
        const formData = new FormData();
        formData.append('file', image);
        formData.append('upload_preset', 'wjfzw5qb'); // Replace 'your_cloudinary_preset' with your actual Cloudinary preset name
        const response = await axios.post('https://api.cloudinary.com/v1_1/dqxvh09uy/image/upload', formData); // Replace 'your_cloud_name' with your actual Cloudinary cloud name
        
        // Set the image URL to the uploaded image URL
        imageUrl = response.data.secure_url;
        console.log(imageUrl);
      }
  
      // Then, save the post data to your backend API
      const postData = {
        title: postHeader,
        body: editorHtml,
        keywords: keywords,
        titlePic: imageUrl // Pass the image URL, whether it exists or not
      };
  
      const response = await instance.post('api/posts', postData); // Replace 'http://localhost:5000/api/posts' with your actual backend API endpoint
  
      // Handle successful response
      console.log('Post saved successfully!');
      toast.success('Post saved successfully!');
      navigate('/posts'); // Navigate to the posts page after saving the post
    } catch (error) {
      console.error('Error saving the post:', error);
      toast.error('Error saving the post');
    }
  };
  

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  return (
    <div className='text-editor'>
      {/* Post Header Input */}
      <h1>Yangi Post</h1>

      <div className="editor-container">
        {/* Quill editor and Left Panel*/}
        <div className='left-panel' style={{ width: "100%", marginBottom: "150px", float: 'left' }}>
          {/* The Post header input */}
          <input
            type="text"
            value={postHeader}
            onChange={(e) => setPostHeader(e.target.value)}
            placeholder="Enter post header"
            style={{ width: '100%', marginBottom: '10px' }}
          />

          {/* It is the quil */}
          <ReactQuill
            theme="snow"
            value={editorHtml}
            onChange={handleEditorChange}
            modules={modules}
            formats={formats}
            style={{ height: '400px', width: '100%' }}
          />
        </div>
        {/* Right panel for keywords and images */}
        <div className="right-panel">
          {/* Keywords input */}
          <input
            type="text"
            value={keywords.join(',')}
            onChange={handleKeywordsChange}
            placeholder="Add keywords (comma-separated)"
            className="keywords-input"
          />

          {/* Image uploader */}
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            className="image-input"
          />

          {/* Image previewer */}
          <div className="image-preview">
          {image && image instanceof Blob && (
  <div className="image-item">
    <img
      src={URL.createObjectURL(image)}
      alt="Preview"
      className="preview-image"
    />
  </div>
)}

          </div>

          {/* Save button */}
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TextEditor;
