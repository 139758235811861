import axios from "axios";

export const instance = axios.create({
    baseURL: "https://back.akbardev.uz/",

})

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        // console.log(config);
        if (token) {
            config.headers.authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        console.log("error");
        return Promise.reject(error);
    }
);
