import React, { useEffect, useState } from 'react'
import "./About.css"

import about from "../../images/about-img.49cbf432cbbdd3d14d7a.jpg"
import CV from "../../images/CV.pdf"

function About() {
    const [age, setAge] = useState(null);

    // Function to calculate age based on birthdate
    function calculateAge(birthdate) {
      const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      
      return age;
    }
  
    // useEffect to calculate age when component mounts
    useEffect(() => {
      // Example birthdate (you can replace it with the actual birthdate)
      const birthdate = new Date('2006-05-22');
      const calculatedAge = calculateAge(birthdate);
      setAge(calculatedAge);
    }, []); // Empty dependency array to run only once when the component mounts
    return  <section className="about" id="about">
      
      {/* <!--   === About Image Starts ===   --> */}
      <div className="about-image">
          <img src={about} alt='' />
          <div className="social-media">
              <i className="fa-brands fa-facebook-f"></i>
              <i className="fa-brands fa-linkedin-in"></i>
              <i className="fa-brands fa-instagram"></i>
              <i className="fa-brands fa-twitter"></i>
          </div>
      </div>
      {/* <!--   === About Image Ends ===   --> */}
  
      {/* <!--   === About Description Starts ===   --> */}
      <div className="about-desc">
          <h3>MEN HAQIMDA</h3>
          <h2>3+ Yillik ish tajribaga ega yosh Full Stack Dasturchi</h2>
          <p>Ma'lumot o'rnida aytish kerakki men hozirga qadar haqiqiy jamoa bilan yuzma yuz ishlamaganman. 3 yil oldin dasturlashni o'rganishni boshlab hozirgi kunda</p>
          <p>o'zimning ish-faoliyatimni olib borayapman. Dunyo bo'ylab 30 dan ortiq mijozlar va hozircha 50 dan ortiq tugallangan proyektlarim bor.</p>
          <div className="about-personal-info">
              <div><span>Ism:</span><span>D Akbarshoh</span></div>
              <div><span>Yosh:</span><span>{age !== null ? `${age} yosh` : 'Calculating...'}</span></div>
              <div><span>Email:</span><span>bloghack9@gmail.com</span></div>
              <div><span>Hobby:</span><span>Fitness</span></div>
          </div>
          <button className="btn download-btn">
  <a 
    href="https://github.com/AkbarshohIlhomovich/akbarshohilhomovich/blob/main/Akbarshoh's%20Resume.pdf" 
    download="Akbarshoh's Resume.pdf"
    style={{ textDecoration: "none", color: "var(--btn-primary)" }}
  >
    Rezyumeni yuklash
  </a>
</button>

      </div>
      {/* <!--   === About Description Ends ===   --> */}
  
  </section>
}

export default About