import React, { useEffect, useState } from 'react';
import './Portfolio.css';
import { instance } from '../src/axios';

function Portfolio() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await instance.get("/projects");

      // Axios returns response object with a status code, no need for .ok property
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      // Axios returns the response data directly
      const data = response.data;

      // Assuming the server returns an array of projects in JSON format
      setProjects(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <section className="portfolio reusable" id="portfolio">
      <h2 className="section-heading">Mening ohirgi ishlarim</h2> <br />

      <div className="work-grid">
        {/* Map through the projects array to dynamically render work items */}
        {projects.map((project) => (
          <div className="work" key={project._id}>
            <div className="work-image">
              <img src={project.pic} alt={`Work ${project._id}`} />
            </div>
            <div className="work-details">
              <h3 className="work-title">{project.title}</h3>
              <p className="work-description">{project.data}</p><br />
              <a href={project.link} className="work-link">
                View Project
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Portfolio;
