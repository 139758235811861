    import React, { useState } from 'react'
    import "./Contact.css"
import { toast } from 'react-toastify';
import { instance } from '../src/axios';
    // import axios from 'axios'

    function Contact() {
        const [formData, setFormData] = useState({
            name: '',
            email: '',
            project: '',
            message: '',
          });
        
          const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          };
        
          const handleSubmit = (e) => {
            e.preventDefault();
        
            
            const responce = instance.post("/projects", formData)
                .catch((error) => {
                    toast.error("Yuborishda hatolik...")
                    console.error('Error sending form data:', error);
                    // Optionally, you can show an error message to the user here
                });
                if (responce) {
                    toast.success("Yuborildi");
                }
                setFormData({
                    name: '',
                    email: '',
                    project: '',
                    message: '',
                  })
          };
        return <section className="contact-form" id="contactForm">
            <div className="contact-row">

                {/* <!--   === Left Column Starts ===   --> */}
                <div className="contact-col column-1">
                    <div className="contactTitle">
                        <h2>Aloqaga chiqing</h2>
                        <p>Taklifingiz bo'lsa yoki ish taklif qilmoqchi bo'lsangiz aloqaga chiqing.</p>
                    </div>

                    {/* <form className="form-1">
                        <div className="inputGroup">
                            <input type="text" name="" required="required" />
                            <label>Your Name</label>
                        </div>

                        <div className="inputGroup">
                            <input type="email" name="" required="required" />
                            <label>Email</label>
                        </div>
                    </form> */}
                    <br /><br />

                    <div className="contactSocialMedia">
                        <a href="https://www.facebook.com/akbar.webdev"><i className="fa-brands fa-facebook-f"></i></a>
                        <a href="#twitter"><i className="fa-brands fa-twitter"></i></a>
                        <a href="https://www.instagram.com/akbar.webdev/"><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://t.me/akbar_webdev"><i className="fa-brands fa-telegram"></i></a>
                    </div>
                </div>
                {/* <!--   === Left Column Ends ===   --> */}

                {/* <!--   === Right Column Starts ===   --> */}
                <div className="contact-col column-2">
                    <form onSubmit={handleSubmit}>
                        <div className='form-1'>
                            <div className='main'>
                                <div className="inputGroup">
                                    <input type="text" name="name" required="required"  value={formData.name} onChange={handleChange}/>
                                    <label>Ismingiz</label>
                                </div>
                                <div className="inputGroup">
                                    <input type="email" name="email" required="required"  value={formData.email} onChange={handleChange}/>
                                    <label>Email</label>
                                </div>
                            </div>

                        <br />
                        <div className="inputGroup">
                            <input type="text" name="project" style={{width: "100%"}} required="required" value={formData.project} onChange={handleChange} />
                            <label>Proyekt</label>
                        </div>
                        </div>

                        <div className='form-2'>
                        <div className="inputGroup">
                            <textarea 
                            name="message"
                            required
                            placeholder='Iltimos siz bilan bog`lanishimiz oson bo`lishi uchun telegram akkauntingizning "username"sini ham yozib keting.'
                            value={formData.message}
                            onChange={handleChange}
                            ></textarea>
                            <label>Habaringiz:</label>
                        </div>
                        <button className="form-button">MESSAGE ME</button>
                        </div>
                    </form>
                </div>
                {/* <!--   === Right Column Ends ===   --> */}

            </div>
        </section>
    }

    export default Contact