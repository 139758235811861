import React from 'react'
import "./Resume.css"

function Resume() {
  return <section className="resume reusable" id="resume">
	
  {/* <!--   === Headings Text Starts ===   --> */}
  <header className="headings">
      <h3>Rezyume</h3>
      <h1>O'qish va Tajriba</h1>
      <p>Bu yerda mening qayerlarda taqsil olganim va qanday tajribalarim borligini yozganman.</p>
  </header>
  {/* <!--   === Headings Text Ends ===   --> */}

  {/* <!--   === Resume Row Starts ===   --> */}
  <div className="resume-row">
      
      {/* <!--   === Left Column Starts ===   --> */}
      <div className="column column-left">
          <header className="sub-heading">
              <h2>O'qish</h2>
          </header>

          <main className="resume-contents">
              <div className="box">
                  <h4>1 yil</h4>
                  <h3>Boshlang'ich dasturlash</h3>
                  <p>Boshlang'ich dasturlashni men 2019-yilda o'rganishni boshlaganman va shundan buyon shug'ullanayapman.</p>
                  <h5 className="vanue">W3 Schools</h5>
              </div>

              <div className="box">
                  <h4>2+ yil</h4>
                  <h3>NodeJs to'liq backend</h3>
                  <p>2 yildan ziyod vaqtimni NodeJs ni o'rganishga sarflaganman va juda katta natijalarga erishganman.</p>
                  <h5 className="vanue">Internet bo'ylab</h5>
              </div>

              <div className="box">
                  <h4>2013 - 2023</h4>
                  <h3>Yuqori maktab</h3>
                  <p>1-sinfdan 6-sinf ohirigacha Rus maktabda o'qib 7- sinfdan Namangan shahridagi 1-sonli IDUMIda o'qib kelmoqdaman va o'qituvchilar juda ham kuchli.</p>
                  <h5 className="vanue">Namangan</h5>
              </div>
          </main>
      </div>
      {/* <!--   === Left Column Ends ===   --> */}

      {/* <!--   === Right Column Starts ===   --> */}
      <div className="column column-right">
          <header className="sub-heading">
              <h2>Tajriba</h2>
          </header>

          <main className="resume-contents">
              <div className="box">
                  <h4>1+ yil</h4>
                  <h3>Frontend Amaliyot</h3>
                  <p>Frontendni o'rganishim bilan men tajribalar orqali juda katta natijalarga erishganman.</p>
                  <h5 className="vanue"> </h5>
              </div>

              <div className="box">
                  <h4>2+ yil</h4>
                  <h3>Backend Amaliyot</h3>
                  <p>Dasturlashni faqat amaliyot bilan o'rganganman shuning uchun backendni ham juda ko'p vaqt amaliyot qilganman.</p>
                  <h5 className="vanue">Jamoa bilan</h5>
              </div>

              <div className="box">
                  <h4>1+ yil</h4>
                  <h3>Python</h3>
                  <p>Python tilini o'rganib maktabimizdan olimpiadaga chiqib Shaharda 1- va Viloyatda fahrli 2-o'rinni egallaganman.</p>
                  <h5 className="vanue">Robocontest</h5>
              </div>
          </main>
      </div>
      {/* <!--   === Right Column Ends ===   --> */}

  </div>
  {/* <!--   === Resume Row Ends ===   --> */}

</section>
}

export default Resume